<template>
  <ul class="social">
    <li
      class="social__item"
      v-for="({url, name}, index) in config.socialsList"
      :key="name"
    >
      <a
          :href="url"
          target="_blank"
          class="social__item-link"
          @click.prevent="$emit('clickSocial', [url, $event, name, index + 1])"
      >
        <img
            :src="getImage(name)"
            class="social__item-image js-social"
            :alt="name"
        />
      </a>
    </li>
  </ul>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'socials-block',
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    getImage (name) {
      try {
        return require(`../../img/socials/${name.toLowerCase()}-logo.webp`)
      } catch (e) {
        return require('../../img/socials/black.webp')
      }
    }
  }
})
</script>

<style lang="scss" src="./styles.scss"></style>
