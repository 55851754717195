<template>
  <div class="wrapper">
    <HeaderComponent
      :config="config"
      :logoLoaded="logoLoaded"
      @click-event="clickOnLogo"
    />
    <main class="main">
      <div class="container">
        <div class="hero__inner">
          <div class="casino">
            <ul class="casino__cards casino__cards-1">
              <li
                v-for="({name, gameTitle, url, casinoBonus1}, index) in config.projects"
                :key="name"
                class="casino-item"
                :class="name"
              >
                <a
                  class="casino-item__link"
                  :href="url"
                  target="_blank"
                  @click.prevent="openLink(url, $event, name, index)"
                >
                  <img
                    v-if="name"
                    :src="require(`./img/casino-${name}.webp`)"
                    class="casino-item__side-image js-casino-logo"
                    alt="casino banner"
                  >
                  <div class="casino-item__content">
                    <div class="casino-item__top">
                      <img
                        v-if="name"
                        :src="require(`./img/casino-${name}.webp`)"
                        class="casino-item__image"
                        alt="casino banner"
                      >
                      <div class="casino-item__game-title">
                        <span class="js-casino-description">{{ gameBonus }} {{ gameTitle }} {{ gameBonusAfter }}</span>
                      </div>
                    </div>
                    <div class="casino-item__bonuses"  v-if="casinoBonus1">
                      <div
                        class="casino-item__bonus casino-item__bonus_left js-bonus-left"
                        v-html="casinoBonus1 || config.casinoBonus1"
                      />
                      <div
                        class="casino-item__bonus casino-item__bonus_right js-bonus-right"
                        v-html="config.casinoBonus2"
                      />
                    </div>
                    <div
                      class="casino-item__promocode js-promocode"
                    >
                      <span class="casino-item__your-promocode">{{ config.yourPromocode }}</span>
                      <span>{{ config.promocode }}</span>
                      <img
                        src="./img/icon-copy.svg"
                        class="casino-item__copy"
                        alt=""
                      >
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
    <FooterComponent :config="config"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import HeaderComponent from './blocks/header'
import FooterComponent from './blocks/footer'

export default defineComponent({
  name: 'template',
  data () {
    return {
      logoLoaded: false
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    HeaderComponent,
    FooterComponent
  },
  computed: {
    gameBonus () {
      return this.config.gameBonus || ''
    },
    gameBonusAfter () {
      return this.config.gameBonusAfter || ''
    }
  },
  created () {
    const body = document.querySelector('body')

    if (this.config.theme) {
      body.classList.add(`theme-${this.config.theme}`)
    }
  },
  methods: {
    async copyToClipboard (text) {
      try {
        await navigator.clipboard.writeText(text)
        this.$emit('copy')
      } catch ($e) {
        console.error('Cannot copy')
      }
    },
    loadImage () {
      this.logoLoaded = true
    },
    addParamsToUrl (url, params) {
      const urlObject = new URL(url)

      Object.keys(params).forEach(key => {
        urlObject.searchParams.append(key, params[key])
      })

      return urlObject.href
    },
    openLink (url, e, name, position) {
      const targetClasslist = e.target.classList
      const urlParamsObj = {
        session_id: window.webDataApi[0].session_id,
        device_id: window.webDataApi[0].device_id,
        utm_source: 'landing'
      }

      if (targetClasslist.contains('js-promocode')) {
        this.copyToClipboard(this.config.promocode)

        window.send_data({
          event: 'Click Event',
          button: 'projects-promocode',
          project: name.toLowerCase(),
          position: position + 1
        })

        return
      }

      if (targetClasslist.contains('js-bonus-left')) {
        urlParamsObj.btag = 'royal-landing'

        window.send_data({
          event: 'Click Event',
          button: 'projects-bonus_link',
          option: 'deposit_bonus',
          project: name.toLowerCase(),
          position: position + 1
        })
      }

      if (targetClasslist.contains('js-bonus-right')) {
        urlParamsObj.btag = 'royal-landing'

        window.send_data({
          event: 'Click Event',
          button: 'projects-bonus_link',
          option: 'registration_bonus',
          project: name.toLowerCase(),
          position: position + 1
        })
      }

      if (targetClasslist.contains('js-casino-logo')) {
        window.send_data({
          event: 'Click Event',
          button: 'projects-bonus_link',
          option: 'logo',
          project: name.toLowerCase(),
          position: position + 1
        })
      }

      if (targetClasslist.contains('js-casino-description')) {
        urlParamsObj.btag = 'royal-landing'

        window.send_data({
          event: 'Click Event',
          button: 'projects-bonus_link',
          option: 'description',
          project: name.toLowerCase(),
          position: position + 1
        })
      }

      if (targetClasslist.contains('js-logo')) {
        window.send_data({
          event: 'Click Event',
          button: 'top-home'
        })
      }

      if (targetClasslist.contains('js-social')) {
        window.send_data({
          event: 'Click Event',
          button: 'top-social_link',
          link: url,
          name: name.toLowerCase(),
          position: position + 1
        })
      }

      console.log(this.addParamsToUrl(url, urlParamsObj))
      window.open(this.addParamsToUrl(url, urlParamsObj), '_blank')
    },
    clickOnLogo (data) {
      const [url, e, name, index] = data
      this.openLink(url, e, name, index)
    }
  }
})

</script>

<style lang="scss" src="./scss/style.scss"></style>
